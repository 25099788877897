import React, {useRef} from 'react';
import {ScrollView, View} from 'react-native';
import responsiveStyle from './style';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import withAuth from '../../auth/withAuth';
import Breadcrumbs from '../../components/Breadcrumbs';
import PolicyDropdown from '../../components/PolicyDropdown';
import ProfileLibrary from '@oneamerica/dxp-ui-module-my-profile';
import {PROFILE_API_BASE_URL} from '../../config/getEnvironmentVars';

const Profile = (props) => {
  const style = responsiveStyle();
  const scrollViewRef = useRef<ScrollView>(null);

  return (
    <ScreenWrapper
      scrollFooter
      paddingTop
      paddingBottom
      paddingHorizontal
      ref={scrollViewRef}>
      <Breadcrumbs />
      <View style={style.cardHeader}>
        <PolicyDropdown
          className=""
          accessToken={props.accessToken}
          text={'My Profile'}
        />
      </View>
      <ProfileLibrary
        accessToken={props.accessToken}
        profileApiUrl={PROFILE_API_BASE_URL}
        appName={'Policyholder'}
        apiClientID={'POLICYHOLDERWEB'}
        authLogout={props.authLogout}
        scrollViewRef={scrollViewRef}
      />
    </ScreenWrapper>
  );
};

export default withAuth(Profile);
