import {Color, Font, Metrics, Responsive} from '@oneamerica/dxp-ui-components';

const styles = (small?: boolean) => {
  return Responsive.create({
    datePicker: {
      width: '100%',
    },
    pressableContainer: {
      width: '100%',
    },
    small: {
      width: 118,
      height: 30,
    },
    smallTextInput: {
      padding: 0,
    },
    icon: {
      position: 'absolute',
      right: 0,
      marginVertical: small ? Metrics.xSmall : Metrics.small,
      marginHorizontal: small ? Metrics.xxSmall : Metrics.small,
    },
    disabled: {
      backgroundColor: Color.input.disabledBackground,
    },
    helperTextWrapper: {
      marginTop: 4,
      marginLeft: 12,
    },
    helperText: {
      marginLeft: 12,
      marginTop: 4,
      alignSelf: 'flex-start',
      fontSize: Font.Small.size,
      color: Color.gray6,
    },
  })();
};
export default styles;
