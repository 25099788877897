import React, {useState} from 'react';
import {Linking, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {DrawerNavigationProp} from '@react-navigation/drawer';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome';
import {faFilePdf} from '@fortawesome/pro-light-svg-icons/faFilePdf';
import {Components} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';
import responsiveStyle from './style';
import RouteConstants from '../../constants/RouteConstants';
import {NetworkManager} from '../../api/NetworkManager';
import {ProcessingTroubleModal} from '../ProcessingTroubleModal';
import {NoStatementsAvailable} from '../NoStatementsAvailable';
import {trackCustomEvent} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

interface LatestStatementsProps {
  onPress?: () => void;
  accessToken: string;
  policyNumber: string | number;
  pageName: string;
}

export const LatestStatement = ({
  onPress,
  accessToken,
  policyNumber,
  pageName,
}: LatestStatementsProps) => {
  const styles = responsiveStyle();
  const [showProcessingTroubleModal, setShowProcessingTroubleModal] =
    useState(false);
  const [showNoStatementVailableModal, setShowNoStatementVailableModal] =
    useState(false);
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  const analyticsApiFail = (pageName) => {
    if (pageName === 'HOME') {
      trackCustomEvent(analyticsTags.CaptureLatestStatementsApiFailureHomePage);
    } else if (pageName === 'POLICY DETAILS') {
      trackCustomEvent(
        analyticsTags.CaptureLatestStatementsApiFailurePolicyDetails,
      );
    }
  };

  const downloadPDF = async (pageName) => {
    try {
      const response = await NetworkManager.getLatestStatementsPDF({
        token: accessToken,
        policyNumber,
      });
      if (response.data.status && response.data.docPdfData) {
        const pdfBase64 = response.data.docPdfData;

        // Check if the PDF is empty
        if (!pdfBase64) {
          setShowNoStatementVailableModal(true);
          return;
        }

        // Convert the base64 data to a Blob and download it
        const pdfBlob = base64ToBlob(pdfBase64);
        const fileUrl = URL.createObjectURL(pdfBlob);
        Linking.openURL(fileUrl);
      } else {
        // Handle the case when no PDF is returned or error occurs
        setShowNoStatementVailableModal(true);
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
      analyticsApiFail(pageName);
      setShowProcessingTroubleModal(true);
    }
  };
  const onCloseProcessingModal = () => {
    setShowProcessingTroubleModal(false);
    setShowNoStatementVailableModal(false);
    if (pageName === 'HOME') {
      navigation.navigate(RouteConstants.home);
    } else if (pageName === 'POLICY DETAILS') {
      navigation.navigate(RouteConstants.policydetails);
    }
  };

  const base64ToBlob = (base64Data: string) => {
    const binaryString = atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], {type: 'application/pdf'});
  };

  const onPressHandler = (pageName) => {
    downloadPDF(pageName);
    onPress?.();
  };

  return (
    <>
      <Components.Link
        className="mt-2"
        onPress={onPressHandler}
        accessibilityLabel="Latest Statement"
        accessibilityRole="link">
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <FontAwesomeIcon
            testID="fpdIcon"
            style={styles.faFilePdfIcon as FontAwesomeIconStyle}
            icon={faFilePdf as IconProp}
            color={ColorConstants.iceBlue}
          />
          <Components.Text style={[styles.latestStatement]}>
            Latest Statement
          </Components.Text>
        </View>
      </Components.Link>
      {showProcessingTroubleModal && (
        <ProcessingTroubleModal
          showModal={showProcessingTroubleModal}
          onClose={onCloseProcessingModal}
        />
      )}
      {showNoStatementVailableModal && (
        <NoStatementsAvailable
          showModal={showNoStatementVailableModal}
          onClose={onCloseProcessingModal}
        />
      )}
    </>
  );
};
