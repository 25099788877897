import React from 'react';
import {Pressable, View} from 'react-native';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {Color, icons} from '@oneamerica/dxp-ui-components';
import responsiveStyles from './styles';

type ControlProps = {
  small?: boolean;
  onPress?: () => void;
  testID?: string;
};
export const Controls = ({small, onPress, testID}: ControlProps) => {
  const styles = responsiveStyles(small);

  return (
    <View style={styles.icon}>
      <Pressable
        accessibilityLabel="Open Calendar"
        onPress={onPress}
        testID={testID}>
        <FontAwesomeIcon
          icon={icons.calendarDay}
          size={15}
          color={Color.gray5}
        />
      </Pressable>
    </View>
  );
};
