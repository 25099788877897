import {Color, Font} from '@oneamerica/dxp-ui-components';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {},
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 1,
    maxHeight: 200,
  },
  selectedOptionsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 5, // Space between the tags and input
  },
  tag: {
    backgroundColor: Color.ice1,
    borderColor: Color.ice4,
    borderWidth: 1,
    padding: 5,
    borderRadius: 5,
    marginRight: 2,
    marginBottom: 5,
    flexDirection: 'row',
    position: 'relative', // Required for absolute positioning of the icon
    maxWidth: '90%', // Limit the max width to ensure wrapping
    alignItems: 'center',
    outlineStyle: 'none',
    fontSize: 14,
  },
  tagText: {
    color: Color.oaBlue,
    flexWrap: 'wrap', // Allow text to wrap
    maxWidth: '110%', // Allow the text more space to wrap
    marginRight: 20, // Space for the icon
    fontFamily: Font.regular.fontFamily,
    fontSize: 14,
  },
  iconStyle: {
    // right: 0, // Aligns icon to the right
    // zIndex: 1, // Ensure the icon is above other elements
    position: 'absolute',
    top: '50%', // Center vertically
    right: 0,
    transform: [{translateY: -6}], // Adjust based on your height
    zIndex: 1,
  },
  optionsList: {
    maxHeight: 200,
    borderColor: 'transparent',
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: 'white',
    marginTop: 1,
    ...Color.shadow,
  },
  helperTextWrapper: {
    marginTop: 4,
    marginLeft: 12,
    lineHeight:2,
  },
  option: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderColor: 'transparent',
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: 'white',
  },
  optionText: {
    marginLeft: 1,
  },
  noBorder: {
    borderWidth: 0,
    borderColor: 'transparent',
    outlineStyle: 'none', // Add this if applicable
    outline: 'none',
  },
});
