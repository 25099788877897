import React, {useRef, useEffect} from 'react';
import {View, ScrollView} from 'react-native';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import ViewIndexedDividend from './components/ViewIndexedDividend/ViewIndexedDividend';
import PremiumsReceived from './components/PremiumsReceived/PremiumsReceived';
import BillingAndPremium from './components/BillingAndPremium';
import {usePolicyDetailsSelector} from './hooks/policyDetails';
import withAuth from '../../auth/withAuth';
import CoverageInformation from './components/CoverageInformation';
import PolicyRelationships from './components/PolicyRelationship';
import PolicyValue from './components/PolicyValue';
import IncomeInformation from './components/IncomeInformation';
import IncomePaymentInformation from './components/IncomePaymentInformation';
import PolicyDropdown from '../../components/PolicyDropdown';
import Breadcrumbs from '../../components/Breadcrumbs';
import ContinuationOfBenefits from './components/ContinuationOfBenefits';
import AnnuityRates from './components/AnnuityRates';
import {PolicyNotes} from '../Home/components/PolicyNotes';
import ErrorWrapper from '../../components/ErrorWrapper';
import {trackPage} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

const PolicyDetails = (props) => {
  const {
    policyRelationshipData,
    billingAndPremiumData,
    premiumData,
    viewIndexedDividendData,
    coverageInformationData,
    incomeInformation,
    loanData = {},
    ltcSectionData,
    policyValueAsOfDate,
    policyValuesData,
    incomePaymentInformationData,
    freeWithdrawalData,
    dateRangeforWithdrawalDateRange,
    addCoveragesData,
    cbData,
    continuationOfBenefitGeneralInfo,
    continuationofBenefitAddCoverage,
    annuityRatesData,
    policyNotes,
    cobContractStatus,
    account,
  } = usePolicyDetailsSelector(props);
  const scrollViewRef = useRef<ScrollView>(null);
  const premiumReceivedRef = useRef(null);
  const coverageInfoRef = useRef(null);
  const indexedDividentRef = useRef(null);
  const incomeInfoRef = useRef(null);
  const policyRelationshipsRef = useRef(null);
  const incomePaymentInformationRef = useRef(null);
  const cobRef = useRef(null);
  const annuityRatesRef = useRef(null);
  const policyValuesRef = useRef(null);
  const billingandPremiumRef = useRef(null);

  useEffect(() => {
    trackPage(analyticsTags.createPolicyDetailsViewTags);
    if (props?.route?.params?.target === 'PremiumReceived') {
      scrollToView(premiumReceivedRef);
    } else if (props?.route?.params?.target === 'PolicyValues') {
      scrollToView(policyValuesRef);
    } else if (props?.route?.params?.target === 'BillingandPremium') {
      scrollToView(billingandPremiumRef);
    } else if (props?.route?.params?.target === 'ViewIndexedDividend') {
      scrollToView(indexedDividentRef);
    } else if (props?.route?.params?.target === 'CoverageInformation') {
      scrollToView(coverageInfoRef);
    } else if (props?.route?.params?.target === 'IncomeInformation') {
      scrollToView(incomeInfoRef);
    } else if (props?.route?.params?.target === 'PolicyRelationships') {
      scrollToView(policyRelationshipsRef);
    } else if (props?.route?.params?.target === 'IncomePaymentInformation') {
      scrollToView(incomePaymentInformationRef);
    } else if (props?.route?.params?.target === 'COB') {
      scrollToView(cobRef);
    } else if (props?.route?.params?.target === 'AnnuityRates') {
      scrollToView(annuityRatesRef);
    } else {
      scrollViewRef?.current?.scrollTo({y: 0, animated: true});
    }
  }, [props?.route?.params]);

  const scrollToView = (ref) => {
    ref?.current?.measureLayout(null, (x, y, width, height) => {
      scrollViewRef?.current?.scrollTo({y: y, animated: true});
    });
  };

  return (
    <ScreenWrapper
      scrollFooter
      paddingTop
      paddingBottom
      paddingHorizontal
      ref={scrollViewRef}>
      <Breadcrumbs />
      {/* POLICY DROPDOWN */}
      <PolicyDropdown
        className={''}
        accessToken={props.accessToken}
        text={'Policy Details'}
      />
      <ErrorWrapper>
        {((loanData !== null && Object.keys(loanData).length > 0) ||
          (ltcSectionData !== null && ltcSectionData.length > 0) ||
          (freeWithdrawalData !== null && freeWithdrawalData.length > 0) ||
          (policyValuesData !== null && policyValuesData.length > 0)) && (
          <View ref={policyValuesRef}>
            <PolicyValue
              loanData={loanData}
              policyValueAsOfDate={policyValueAsOfDate}
              ltcSectionData={ltcSectionData}
              freeWithdrawalData={freeWithdrawalData}
              policyValuesData={policyValuesData}
              dateRangeforWithdrawalDateRange={dateRangeforWithdrawalDateRange}
            />
          </View>
        )}

        {annuityRatesData.length > 0 && (
          <View ref={annuityRatesRef}>
            <AnnuityRates annuityRatesData={annuityRatesData} />
          </View>
        )}

        {billingAndPremiumData.length > 0 && (
          <View ref={billingandPremiumRef}>
            <BillingAndPremium
              billingAndPremiumData={billingAndPremiumData}
              accessToken={props.accessToken}
              policyNumber={account?.number}
            />
          </View>
        )}
        {coverageInformationData.length > 0 && (
          <View ref={coverageInfoRef}>
            <CoverageInformation
              coverageInformationData={coverageInformationData}
              addCoveragesData={addCoveragesData}
            />
          </View>
        )}
        {viewIndexedDividendData.length > 0 && (
          <View ref={indexedDividentRef}>
            <ViewIndexedDividend
              viewIndexedDividendData={viewIndexedDividendData}
            />
          </View>
        )}
        {premiumData?.length > 0 && (
          <View ref={premiumReceivedRef}>
            <PremiumsReceived premiumData={premiumData} />
          </View>
        )}

        {policyRelationshipData?.length > 1 && (
          <View ref={policyRelationshipsRef}>
            <PolicyRelationships props={props} />
          </View>
        )}
        {incomePaymentInformationData?.length > 0 && (
          <View ref={incomePaymentInformationRef}>
            <IncomePaymentInformation
              incomePaymentInformationData={incomePaymentInformationData}
            />
          </View>
        )}

        {incomeInformation?.length > 0 && (
          <View ref={incomeInfoRef}>
            <IncomeInformation IncomeInformationData={incomeInformation} />
          </View>
        )}
        {continuationOfBenefitGeneralInfo?.length > 0 &&
          cobContractStatus !== 'Terminated' &&
          cobContractStatus !== 'Suspended' && (
            <View ref={cobRef}>
              <ContinuationOfBenefits
                coverageInformationData={cbData}
                continuationOfBenefitGeneralInfo={
                  continuationOfBenefitGeneralInfo
                }
                continuationofBenefitAddCoverage={
                  continuationofBenefitAddCoverage
                }
              />
            </View>
          )}
        {policyNotes !== null && policyNotes?.length > 0 && (
          <PolicyNotes policyNotes={policyNotes} />
        )}
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default withAuth(PolicyDetails);
