import React, {useContext, useMemo, JSX} from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../../style';
import withAuth from '../../../../auth/withAuth';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {
  columnConfiguration,
  childRowsColumnConfig,
  columnWidthArr,
} from './columnConfig';
import {getScreenType, prepareCurrentElectionsData} from '../../../../utils';
import InvestmentChart from '../../../../components/InvestmentTable';

const ConfirmAllocations = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const investments = useSelector(
    (state: any) => state?.investments?.getInvestmentOptionData,
  );
  const selectedTab = props?.route?.params?.tab === 'Future-Elections' ? 1 : 0;
  const isLargeScreen = BreakpointConstants.isLG(width);
  const screenType = getScreenType(width);

  const getPieChartSize = () => {
    if (isLargeScreen) {
      return {height: 319, width: 319};
    }
    return {height: 290, width: 290};
  };
  const renderCards = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (investments?.data?.items[0]?.investments?.length) {
      const investmentFormattedData = prepareCurrentElectionsData(
        props.editedData,
        false,
      );
      props.previousData = investmentFormattedData;
      const mappedData = investmentFormattedData.AllAssets;
      elements.push(
        <InvestmentChart
          className="mt-0"
          key={'confirm-ioe-table'}
          data={mappedData}
          accountBalance={100}
          showChildRows={false}
          columnWidthArr={columnWidthArr}
          columnConfig={columnConfiguration}
          childRowsColumnConfig={childRowsColumnConfig}
          assetTotal={investmentFormattedData.percentRoundTotal}
          horizontal={!isLargeScreen}
          legendTableTitle={{
            text: '',
            titleStyle: style.headingTable,
          }}
          showPieChart={false}
          showLegendTable={true}
          showAlternateRowColors={false}
          showBorder={false}
          fullHeight={true}
          setIsErrorInTable={props.setIsErrorInTable}
          setIsDirty={props.setIsDirty}
          isMobileTableFooter={!isLargeScreen}
        />,
      );
    }
    return elements;
  }, [screenType, selectedTab, investments]);

  const renderCharts = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (investments?.data?.items[0]?.investments?.length) {
      const tempEditedData = [...props.editedData];
      const investmentFormattedData = prepareCurrentElectionsData(
        props.editedData,
        false,
      );
      const newElectionsData = prepareCurrentElectionsData(
        tempEditedData,
        true,
      );
      props.previousData = investmentFormattedData;
      const mappedData = investmentFormattedData.mappedData;
      const newElectionsMappedData = newElectionsData.mappedData;
      const pieChartSize = getPieChartSize();
      const isMobile = screenType === 'SM';
      elements.push(
        <div className="avoid-page-break">
          <View
            className="lg:pr-5"
            key={'confirm-ioe-current-elections-chart'}
            style={style.pieChartColumn}>
            <View>
              <Components.Text style={style.piechartHeading}>
                Current Elections
              </Components.Text>
            </View>
            <InvestmentChart
              className="mt-0"
              data={mappedData}
              accountBalance={100}
              showChildRows={false}
              pieChartHeight={pieChartSize.height}
              pieChartWidth={pieChartSize.width}
              columnWidthArr={columnWidthArr}
              columnConfig={columnConfiguration}
              childRowsColumnConfig={childRowsColumnConfig}
              assetTotal={investmentFormattedData.percentRoundTotal}
              horizontal={isMobile}
              legendTableTitle={{
                text: '',
                titleStyle: style.headingTable,
              }}
              showPieChart={true}
              showLegendTable={false}
              showAlternateRowColors={false}
              fullHeight={true}
              setIsErrorInTable={props.setIsErrorInTable}
              setIsDirty={props.setIsDirty}
            />
          </View>
        </div>,
      );
      elements.push(
        <div className="avoid-page-break">
          <View
            className="lg:pl-5"
            key={'confirm-ioe-new-elections-chart'}
            style={style.pieChartColumn}>
            <View>
              <Components.Text style={style.piechartHeading}>
                New Elections
              </Components.Text>
            </View>
            <InvestmentChart
              className="mt-0"
              data={newElectionsMappedData}
              accountBalance={100}
              showChildRows={false}
              pieChartHeight={pieChartSize.height}
              pieChartWidth={pieChartSize.width}
              columnWidthArr={columnWidthArr}
              columnConfig={columnConfiguration}
              childRowsColumnConfig={childRowsColumnConfig}
              assetTotal={investmentFormattedData.percentRoundTotal}
              horizontal={isMobile}
              legendTableTitle={{
                text: '',
                titleStyle: style.headingTable,
              }}
              showPieChart={true}
              showLegendTable={false}
              showAlternateRowColors={false}
              fullHeight={true}
              setIsErrorInTable={props.setIsErrorInTable}
              setIsDirty={props.setIsDirty}
            />
          </View>
        </div>,
      );
    }
    return elements;
  }, [screenType, selectedTab, investments]);

  return (
    <View>
      <div id="confirmpagetable">{renderCards}</div>
      <View
        className="flex-col md:flex-row"
        style={{
          flexDirection: props.flexDirection,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {renderCharts}
      </View>
    </View>
  );
};

export default withAuth(ConfirmAllocations);
