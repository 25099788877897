import React, {RefObject, useContext} from 'react';
import {StyleProp, TextStyle} from 'react-native';
import {useReactToPrint} from 'react-to-print';
import {Button, Components, Theme} from '@oneamerica/dxp-ui-components';
import BreakpointConstants from '../../constants/BreakpointConstants';
interface PrintableProps {
  label?: string;
  style?: StyleProp<TextStyle>;
  theme?: string;
  id?: string;
  contentRef: RefObject<Element | Text>;
  onPrint?: () => void;
  documentTitle?: string;
  printStyle?: string;
  className?: string;
  size?: number;
  color?: string;
  actionComponent?: 'button' | 'link';
  pageSize?: string;
  pageOrientation?: 'landscape' | 'portrait';
}
export const Printable = ({
  className,
  actionComponent = 'button',
  label = 'Print',
  style = null,
  theme = 'tertiary',
  id = 'printBtn',
  contentRef,
  onPrint,
  documentTitle,
  printStyle = '',
  pageSize = 'letter',
  pageOrientation = 'landscape',
  size,
  color,
}: PrintableProps) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const isMobile = !BreakpointConstants.isMD(width);
  const deviceSpecificStyle = isMobile
    ? `
  @page {
    size: ` +
      pageSize +
      ` ` +
      pageOrientation +
      `;
    margin: 0mm 0mm 0mm 0mm;
    width: 100%;
    height: 100%;
  }
  `
    : `
  @page {
    size: ` +
      pageSize +
      ` ` +
      pageOrientation +
      `;
    margin: 2mm 6mm 2mm 6mm;
    width: 100%;
    height: 100%;
  }
  `;
  const pageStyle =
    `
    @media print {
    ::-webkit-scrollbar {
      display: none;
    }
  
    html, body {
      margin: 0 !important;
      padding: 0 !important;
      background-color: white;
      -webkit-print-color-adjust: exact; 
      print-color-adjust: exact;
    }
    .page-break {
      display: none;
      page-break-before: auto;
    }

    .avoid-page-break {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    .print-divider {
      width: 100%;
      height: 1mm;
      margin-top: 1mm;
    }
      
  }
  /* Safari specific print styling */
  @media print and (-webkit-min-device-pixel-ratio:0){
    .page-break {
      page-break-before: avoid;
    }
  }
  ` +
    deviceSpecificStyle +
    printStyle;

  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle,
    pageStyle,
  });
  const print = () => {
    onPrint?.();
    handlePrint();
  };
  return (
    <>
      {actionComponent === 'link' ? (
        <Components.Link
          className={className}
          style={style}
          hoverStyle={{textDecorationLine: 'none'}}
          textStyle={{fontWeight: 500}}
          size={size}
          color={color}
          accessibilityRole="link"
          id={id}
          onPress={print}
          theme={theme}>
          {label}
        </Components.Link>
      ) : (
        <Button
          className={className}
          style={style}
          accessibilityRole="button"
          id={id}
          onPress={print}
          theme={theme}>
          {label}
        </Button>
      )}
    </>
  );
};
