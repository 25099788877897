import {env} from '../InjectEnv.js';

const MULE_API_BASE_URL = env.REACT_APP_MULE_API_BASE_URL;
const AEM_API_BASE_URL = env.REACT_APP_AEM_API_BASE_URL;
const OKTA_CLIENT_ID = env.REACT_APP_OKTA_CLIENT_ID;
const OKTA_ISSUER_URL = env.REACT_APP_OKTA_ISSUER_URL;
const APP_LOGIN_URL = env.REACT_APP_LOGIN_URL;
const APP_LOGOUT_URL = env.REACT_APP_LOGOUT_URL;
const PSR_IFRAME_SRC = env.REACT_APP_PSR_IFRAME_SRC;
const PROFILE_API_BASE_URL = env.REACT_APP_PROFILE_API_BASE_URL;
const PROFILE_LOGOUT_TIME = env.REACT_APP_PROFILE_LOGOUT_TIME;
const ACCOUNT_NAVIGATOR_URL_PH = env.REACT_APP_ACCOUNT_NAVIGATOR_URL_PH;
const ANALYTICS_SCRIPT = env.REACT_APP_ANALYTICS_SCRIPT;
const QUALTRICS_SCRIPT = env.REACT_APP_QUALTRICS_SCRIPT;
const QUALTRICS_ID = env.REACT_APP_QUALTRICS_ID;
const VINTAGE_PREFIX = env.REACT_APP_VINTAGE_PREFIX;

export {
  MULE_API_BASE_URL,
  AEM_API_BASE_URL,
  OKTA_CLIENT_ID,
  OKTA_ISSUER_URL,
  APP_LOGIN_URL,
  APP_LOGOUT_URL,
  PSR_IFRAME_SRC,
  PROFILE_API_BASE_URL,
  PROFILE_LOGOUT_TIME,
  ACCOUNT_NAVIGATOR_URL_PH,
  QUALTRICS_SCRIPT,
  QUALTRICS_ID,
  ANALYTICS_SCRIPT,
  VINTAGE_PREFIX,
};
