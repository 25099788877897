import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {View} from 'react-native';
import {
  BreakpointUtils,
  Components,
  Theme,
  RadioGroup,
  RadioButton,
  LoadingIndicator,
} from '@oneamerica/dxp-ui-components';
import {SelectInput} from '../../components/SelectInput';
import Breadcrumbs from '../../components/Breadcrumbs';
import ErrorWrapper from '../../components/ErrorWrapper';
import PolicyDropdown from '../../components/PolicyDropdown';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import responsiveStyle from './style';
import {
  renderReportsNotes,
  generateYears,
  MONTHS,
  isValidDateForLeapYear,
  isDateValid,
} from './helpers';
import {useSelector, useDispatch} from 'react-redux';
import {DatePicker} from '../../components/DatePicker';
import {ErrorListProps} from '@oneamerica/dxp-ui-components/lib/typescript/src/components/DatePicker';
import moment from 'moment';
import AutocompleteMultiple from '../../components/MultiSelectInput';
import {useIsFocused, useNavigation} from '@react-navigation/native';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import RouteConstants from '../../constants/RouteConstants';
import {DrawerNavigationProp} from '@react-navigation/drawer';
import {
  GET_MY_REPORTS_DATA,
  GET_MY_REPORTS_DAY_DATA,
} from '../../redux/myReports/types';
import InvestmentUnitValues from './reportTable';
import InvestmentUnitValuesDay from './reportTableDay';

const MyReports = (props) => {
  const contentRef = useRef(null);
  const contentPrintDaysRef = useRef(null);
  const dispatch = useDispatch();
  const [reportType, setReportType] = useState<string>();
  const [reportFilterType, setReportFilterType] = useState('monthandyear');
  const policyIssuedateDetails = useSelector((state: any) => state?.policies);

  const DATEFORMAT = 'MM/DD/YYYY';
  const currentDate = new Date();
  const getIssueDate =
    policyIssuedateDetails?.getPoliciesListData?.data?.policySummary
      ?.policyHighlights[0]?.generalPolicyData?.issueDate;
  const policyIssueDate = moment(
    moment(getIssueDate).format(DATEFORMAT),
  ).toDate();
  const maxDate = moment(currentDate).subtract(1, 'd').toDate();

  const [selectedInvestment, setSelectedInvestment] = useState<string[]>([]);

  const {...policyData} = useSelector((state: any) => state.policies);
  const investments =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights?.[0]
      ?.investments;
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  const SELECT_ALL_OPTION = {label: 'Select All', value: 'select-all'};
  const SELECT_MINE_OPTION = {label: 'Select Mine', value: 'select-mine'};

  const investMineOptions = investments
    ?.filter((ele) => ele.type !== 1 && ele.value > 0)
    ?.map((investment) => ({
      label: investment.investmentName,
      value: investment.investmentId,
    }));

  const investAllOptions = investments
    ?.filter((ele) => ele.type !== 1)
    ?.map((investment) => ({
      label: investment.investmentName,
      value: investment.investmentId,
    }));

  const investNames = investments
    ?.filter((ele) => ele.type !== 1)
    ?.map((investment) => ({
      label: investment.investmentName,
      value: investment.investmentId,
    }));

  if (investNames) {
    investNames.splice(0, 0, SELECT_ALL_OPTION, SELECT_MINE_OPTION);
  }

  const newInvestNames = investNames?.filter((item) =>
    selectedInvestment.includes(item.value),
  );

  const {width} = useContext(Theme.ResponsiveContext);
  const styles = responsiveStyle(width);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const issueDate = new Date(getIssueDate);
  const issueYear = issueDate.getFullYear();
  const issueMonth = issueDate.getMonth();
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const [errorList, setErrorList] = useState<ErrorListProps[]>([]);
  const [showProcessingTroubleModal, setShowProcessingTroubleModal] =
    useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedDayReport, setIsSubmittedDayReport] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [reportTable, setReportTable] = useState({month: '', year: ''});
  const [reportDaysData, setReportDaysData] = useState(null);

  const reportsStateData = useSelector((state: any) => state.myReports);
  const prevMonth = useRef(selectedMonth);
  const prevYear = useRef(selectedYear);

  const getMonthOptions = () => {
    if (!selectedYear) {
      return MONTHS;
    }
    if (Number(selectedYear) === Number(issueYear)) {
      return MONTHS.filter((_, index) => index >= issueMonth);
    } else if (Number(selectedYear) === Number(currentYear)) {
      return MONTHS.filter((_, index) => index <= currentMonth);
    }
    return MONTHS;
  };

  useEffect(() => {
    if (reportsStateData.data && reportFilterType === 'monthandyear') {
      trackCustomEvent(
        analyticsTags.CaptureMyReportsSubmitMonthAndYearSuccessEventTags,
      );
      const investmentData = reportsStateData?.data?.items.flatMap(
        (item) => item.investmentUnitValueItems,
      );
      setReportData(investmentData);
    } else if (reportsStateData.error && reportFilterType === 'monthandyear') {
      trackCustomEvent(
        analyticsTags.CaptureMyReportsSubmitMonthAndYearFailureEventTags,
      );
      console.error('Error fetching report data:', reportsStateData.error);
      setShowProcessingTroubleModal(true);
      previousInvestment.current = [];
    } else if (reportsStateData.daysData && reportFilterType === 'day') {
      trackCustomEvent(analyticsTags.CaptureMyReportsSubmitDaySuccessEventTags);
      const data = reportsStateData?.daysData?.items.flatMap(
        (item) => item.investmentUnitValueItems,
      );
      setReportDaysData(data);
    } else if (reportsStateData.errorDays && reportFilterType === 'day') {
      trackCustomEvent(analyticsTags.CaptureMyReportsSubmitDayFailureEventTags);
      console.error('Error fetching report data:', reportsStateData.error);
      setShowProcessingTroubleModal(true);
      previousDate.current = undefined;
    }
  }, [reportsStateData]);

  const previousInvestment = useRef(selectedInvestment);
  const previousDate = useRef(selectedDate);
  const reportDataHandler = useCallback(() => {
    if (reportFilterType === 'monthandyear') {
      if (
        JSON.stringify(previousInvestment.current) ===
          JSON.stringify(selectedInvestment) &&
        JSON.stringify(selectedMonth) === JSON.stringify(prevMonth.current) &&
        JSON.stringify(selectedYear) === JSON.stringify(prevYear.current)
      ) {
        return;
      }
      previousInvestment.current = selectedInvestment;
      prevMonth.current = selectedMonth;
      prevYear.current = selectedYear;
      const index = MONTHS.findIndex((month) => month === selectedMonth);
      const formattedMonth = (index + 1).toString().padStart(2, '0');
      trackCustomEvent(
        analyticsTags.CaptureMyReportsMonthAndYearSubmitButtonTags,
      );
      setReportTable((pre) => ({
        ...pre,
        month: selectedMonth,
        year: selectedYear,
      }));
      setIsSubmitted(true);

      let selectedOptionsForApi;
      if (selectedInvestment.toString() === 'select-all') {
        selectedOptionsForApi = investAllOptions.map((opt) => opt.value);
      } else if (selectedInvestment.toString() === 'select-mine') {
        selectedOptionsForApi = investMineOptions.map((opt) => opt.value);
      } else {
        selectedOptionsForApi = selectedInvestment;
      }

      dispatch({
        type: GET_MY_REPORTS_DATA,
        payload: {
          token: policyIssuedateDetails?.token,
          policyNumber:
            policyIssuedateDetails?.getPoliciesListData?.data?.policySummary
              ?.account?.number,
          month: formattedMonth,
          year: selectedYear,
          investmentIds: selectedOptionsForApi || [],
          isLoading: true,
        },
      });
    } else {
      if (previousDate.current === selectedDate) {
        return;
      }
      previousDate.current = selectedDate;
      trackCustomEvent(analyticsTags.CaptureMyReportsDaySubmitButtonTags);
      setIsSubmittedDayReport(true);
      dispatch({
        type: GET_MY_REPORTS_DAY_DATA,
        payload: {
          token: policyIssuedateDetails?.token,
          policyNumber:
            policyIssuedateDetails?.getPoliciesListData?.data?.policySummary
              ?.account?.number,
          day: moment(selectedDate).format('YYYYMMDD'),
          isLoading: true,
        },
      });
    }
  }, [
    selectedInvestment,
    selectedMonth,
    selectedYear,
    selectedDate,
    reportFilterType,
    policyIssuedateDetails,
    analyticsTags,
  ]);

  const linkStyleContactUs = {
    style: styles.linkContactUs,
    linkStyle: styles.linkContactUs,
    linkHoverStyle: styles.linkContactUs,
    hoverStyle: styles.linkContactUsHover,
    focusStyle: styles.linkContactUsFocus,
  };
  const onCloseProcessingModal = () => {
    setShowProcessingTroubleModal(false);
    navigation.navigate(RouteConstants.myreports);
  };
  const navigateToContactUs = () => {
    setShowProcessingTroubleModal(false);
    navigation.navigate(RouteConstants.contactus);
  };
  const processingTroubleErrorModal = () => (
    <Components.DialogModal
      style={styles.smModalSize}
      backgroundColor="#F3F9FE"
      width={365}
      padding={20}
      visible={showProcessingTroubleModal}
      Header={
        <Components.Text style={styles.modalTitle}>
          Processing trouble
        </Components.Text>
      }
      onClose={() => onCloseProcessingModal()}>
      <Components.Text className="grow-0 mt-[16px]">
        {'Apologies, we’re having some trouble processing your request.\n\n'}
        {
          'If you have any questions, please reference the Policy Services section on the '
        }
        <Components.Link
          onPress={navigateToContactUs}
          accessibilityLabel="Contact Us"
          accessibilityRole="button"
          {...linkStyleContactUs}>
          Contact Us
        </Components.Link>{' '}
        {'page.'}
      </Components.Text>
    </Components.DialogModal>
  );

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      setReportType('');
      setSelectedMonth('');
      setSelectedYear('');
      setSelectedInvestment([]);
      setSelectedDate(undefined);
      setIsSubmitted(false);
      setIsSubmittedDayReport(false);
      setReportData(null);
      setReportDaysData(null);
      setReportFilterType('monthandyear');
      setErrorList([]);
    }
  }, [isFocused, getIssueDate]);

  useEffect(() => {
    trackPage(analyticsTags.createMyReportsPageViewTags);
  }, []);

  const onChangeDate = (date) => {
    // Debug output
    if (date !== '') {
      if (date === 'Invalid date') {
        setSelectedDate(undefined);
      } else if (
        !moment(date).isValid() ||
        date.length !== 10 ||
        !isValidDateForLeapYear(date, DATEFORMAT)
      ) {
        createErrorList('Please enter a valid date.');
      } else if (
        moment(date).isValid() &&
        moment(date, DATEFORMAT).isBefore(moment(policyIssueDate, DATEFORMAT))
      ) {
        createErrorList(
          'You must not select a date before the policy issue date of ' +
            moment(policyIssueDate).format(DATEFORMAT) +
            '.',
        );
      } else if (
        moment(date).isValid() &&
        moment(date, DATEFORMAT).isAfter(moment(maxDate, DATEFORMAT))
      ) {
        createErrorList('You must select a date before today`s date.');
      } else {
        setErrorList([]);
        setSelectedDate(moment(date, DATEFORMAT).format(DATEFORMAT));
      }
    } else {
      setErrorList([]);
      setSelectedDate(undefined);
    }
  };

  const createErrorList = (errorMessage: string) => {
    const errorListData = [{errorItem: errorMessage}];
    setErrorList(errorListData);
  };
  // Effect to enable or disable the submit button
  const isSubmitEnabled =
    reportFilterType === 'day'
      ? selectedDate && errorList.length === 0
      : selectedYear && selectedMonth && selectedInvestment.length > 0;

  return (
    <ScreenWrapper scrollFooter paddingTop paddingBottom paddingHorizontal>
      <Breadcrumbs />
      <PolicyDropdown
        className={'my-6'}
        accessToken={props.accessToken}
        text={'My Reports'}
      />
      <ErrorWrapper>
        {investNames && (
          <>
            <Components.Card>
              <Components.Text style={styles.contentTitle} className="w-full">
                {
                  'With these reports, you can view and print details for specific timeframes.'
                }
              </Components.Text>
              <View style={[styles.horizontalDivider]} />
              <View className="mt-5 lg:w-full lg:flex-row">
                <View className="lg:w-1/4">
                  <SelectInput
                    testID="selectReportDropdown"
                    label={!reportType ? 'Select Report' : ''}
                    onPress={(value) => {
                      if (typeof value === 'string') {
                        setReportType(value);
                      }
                    }}
                    optionsLength={1}
                    options={[
                      {
                        label: 'Investment Unit Values',
                        value: 'Investment Unit Values',
                      },
                    ]}
                    value={reportType}
                  />
                </View>
                {reportType && (
                  <View className="mt-10 lg:mt-0 lg:ml-7 lg:w-full flex-1 flex-row items-center">
                    <Components.Text style={styles.ReportBy}>
                      {'Report by'}
                    </Components.Text>
                    <View>
                      <RadioGroup
                        vertical={false}
                        //@ts-ignore
                        columns={['auto', 'auto']}
                        value={reportFilterType}
                        onChange={(val) => {
                          setReportFilterType(val as string);
                          setErrorList([]);
                          setReportData(null);
                          setReportDaysData(null);
                          setIsSubmitted(false);
                          setIsSubmittedDayReport(false);
                          previousInvestment.current = [];
                          previousDate.current = undefined;
                        }}>
                        <RadioButton id="monthandyear" testID="monthandyear">
                          {'Month & Year'}
                        </RadioButton>
                        <RadioButton id="day" testID="day">
                          {'Day'}
                        </RadioButton>
                      </RadioGroup>
                    </View>
                  </View>
                )}
              </View>
              {reportType && reportFilterType === 'monthandyear' && (
                <View>
                  <View className="flex flex-row w-full sm:w-full md:w-2/5 lg:w-3/5 justify-start lg:justify-end mt-5">
                    <View className="w-1/2 lg:w-[27%] pr-1">
                      <SelectInput
                        optionsLength={generateYears(getIssueDate).length}
                        dropdownHeight={
                          generateYears(getIssueDate).length >= 10
                            ? generateYears(getIssueDate).length * 7
                            : generateYears(getIssueDate).length * 20
                        }
                        label="Year"
                        options={generateYears(getIssueDate)
                          ?.reverse()
                          .map((year) => ({
                            label: year,
                            value: year,
                          }))}
                        onPress={(year: any) => {
                          setSelectedYear(year);
                          setSelectedMonth('');
                        }}
                        value={selectedYear}
                      />
                    </View>
                    <View className="w-1/2 lg:w-[27%] pl-1">
                      <SelectInput
                        label="Month"
                        dropdownHeight={getMonthOptions().length * 15}
                        options={getMonthOptions().map((month) => ({
                          label: month,
                          value: month,
                        }))}
                        value={selectedMonth}
                        optionsLength={getMonthOptions().length}
                        onPress={(month: any) => setSelectedMonth(month)}
                      />
                    </View>
                  </View>
                  <View
                    className="flex flex-column w-full sm:w-full md:w-1/2 lg:w-3/5 justify-center lg:justify-center mt-5"
                    style={{
                      marginLeft: BreakpointUtils.isLG(width) ? '27.5%' : 0,
                      position: 'relative',
                      zIndex: -1,
                    }}>
                    <View className="w-full lg:w-[55%] pr-1 mb-5">
                      <AutocompleteMultiple
                        label={'Investment Options'}
                        options={investNames}
                        helperText="You may select all investments, just your investments or multiple individual investments."
                        selectedOptions={newInvestNames?.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        maxSelections={99}
                        onSelect={(values) => {
                          setSelectedInvestment(values);
                        }}
                      />
                    </View>
                  </View>
                </View>
              )}
              {reportType && reportFilterType === 'day' && (
                <View
                  className="flex flex-column w-full sm:w-full md:w-2/5 lg:w-3/5 justify-center lg:justify-center mt-5"
                  style={{
                    marginLeft: BreakpointUtils.isLG(width) ? '27.5%' : 0,
                    position: 'relative',
                    zIndex: -1,
                  }}>
                  <View className="w-full lg:w-[45%] pr-1 mb-5">
                    <DatePicker
                      format={DATEFORMAT}
                      label="Date"
                      date={isDateValid(selectedDate, DATEFORMAT)}
                      placeholder="MM/DD/YYYY"
                      minDate={policyIssueDate}
                      maxDate={maxDate}
                      helperText="Enter a date prior to today's date."
                      onChange={(date) => {
                        // Fired when we select Date from Calendar icon
                        setErrorList([]);
                        onChangeDate(
                          moment(date, DATEFORMAT).format(DATEFORMAT),
                        );
                      }}
                      onBlur={(e) => {
                        // Fired when focus out in case of calendar and key strokes
                        onChangeDate(e?.nativeEvent?.text);
                      }}
                      onChangeText={(value) => {
                        // Fired on every key stroke
                        onChangeDate(value);
                      }}
                      ErrorList={errorList}
                    />
                  </View>
                </View>
              )}
              {((reportType && reportFilterType === 'day') ||
                (reportType && reportFilterType === 'monthandyear')) && (
                <View
                  className="mb-3"
                  style={{
                    position: 'relative',
                    zIndex: -1,
                  }}>
                  <View className="flex sm:flex-row">
                    <Components.Button
                      theme="primary"
                      disabled={!isSubmitEnabled}
                      onPress={reportDataHandler}
                      style={{
                        marginLeft: BreakpointUtils.isLG(width) ? '27.5%' : 0,
                        width: BreakpointUtils.isSM(width) ? 'auto' : '100%',
                      }}>
                      Submit
                    </Components.Button>
                  </View>
                </View>
              )}
              <LoadingIndicator
                loading={
                  reportsStateData?.loading || reportsStateData?.loadingDays
                }
              />
              {isSubmitted &&
                reportData &&
                !reportsStateData.loading &&
                !reportsStateData.error && (
                  <View ref={contentRef}>
                    <InvestmentUnitValues
                      investmentData={reportData}
                      month={reportTable.month}
                      year={reportTable.year}
                    />
                  </View>
                )}
              {isSubmittedDayReport &&
                reportDaysData &&
                !reportsStateData.loadingDays &&
                !reportsStateData.errorDays && (
                  <View ref={contentPrintDaysRef}>
                    <InvestmentUnitValuesDay
                      investmentData={reportDaysData}
                      day={selectedDate}
                    />
                  </View>
                )}
            </Components.Card>
            {showProcessingTroubleModal && processingTroubleErrorModal()}
            {renderReportsNotes?.(width)}
          </>
        )}
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default MyReports;
