import React, {useState, useEffect, useRef} from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Linking,
} from 'react-native';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronSquareDown} from '@fortawesome/pro-solid-svg-icons/faChevronSquareDown';
import {faChevronSquareUp} from '@fortawesome/pro-solid-svg-icons/faChevronSquareUp';
import {Color, OATable} from '@oneamerica/dxp-ui-components';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import responsiveStyle from './style';
import moment from 'moment';
import {isMobile} from './helpers';
import {Printable} from '../../components/Printable';

const {width} = Dimensions.get('window');
const styles = responsiveStyle(width);
interface UnitValue {
  unitValueDate: string;
  value: number | null;
  changeFromLastDate: number;
}

interface Investment {
  investmentId: string;
  investmentNameShort: string | null;
  investmentName: string;
  assetClassName: string;
  assetClassValue: string;
  investmentStyle: string | null;
  investmentSheetURL: string;
  investmentDisclosureURL: string | null;
  disclaimerLocalKey: string[];
  restriction: string[];
  regType: string | null;
  vendorPriceId: string | null;
  securityId: string | null;
  value: number;
  percentByTotalValue: number;
  frozen: boolean;
  retired: boolean;
  closed: boolean;
  invTypeValue: string | null;
  type: number;
  planDefaultElectionPercentage: number;
}

interface InvestmentUnitValueItem {
  investment: Investment;
  unitValue: UnitValue;
}

interface InvestmentUnitValuesDayProps {
  investmentData: InvestmentUnitValueItem[];
  day?: string;
}

interface ChildRowsInvestment {
  investmentName: string;
  unitValueDate: string;
  value: number | null;
  changeFromLastDate: number;
}

interface investmentClass {
  assetClassName: string;
  assetClassValue: string;
  investmentName: string;
  investmentSheetURL: string;
  unitValue: UnitValue;
  investments: ChildRowsInvestment[];
}

const DATEFORMAT = 'MM/DD/YYYY';
const InvestmentUnitValuesDay: React.FC<InvestmentUnitValuesDayProps> = ({
  investmentData = [],
  day,
}) => {
  const [expandedFunds, setExpandedFunds] = useState<{[key: string]: boolean}>(
    {},
  );
  const [isVisible, setIsVisible] = useState(false);
  const [showPDFLink, setShowPDFLink] = useState(false);
  const [changeDate, setChangeDate] = useState('');

  useEffect(() => {
    if (investmentData.length > 0) {
      const dayBeforeSelectedDate = moment(day).subtract(1, 'd').toDate();
      setChangeDate(
        moment(dayBeforeSelectedDate, DATEFORMAT).format(DATEFORMAT),
      );
    }
  }, [day, investmentData]);

  const toggleExpand = (fundId: string) => {
    setExpandedFunds((prev) => ({
      ...prev,
      [fundId]: !prev[fundId],
    }));
    setShowPDFLink(!showPDFLink);
  };
  const contentRef = useRef(null);
  const toggleVisibility = () => {
    setIsVisible((prevVisibility) => {
      const shouldExpandAll = !prevVisibility;
      setExpandedFunds(
        shouldExpandAll
          ? Object.fromEntries(
              investmentData.map((_, index) => [`fund${index}`, true]),
            )
          : {},
      );

      return shouldExpandAll;
    });
  };

  const renderFundItem = (fund, fundIndex: number) => {
    const isExpanded = expandedFunds[`fund${fundIndex}`] || false;

    const tableData = fund.map((item) => {
      return {
        cells: [
          item.investmentSheetURL !== null ? (
            <View className={isMobile() ? 'h-[35px]' : ''}>
              <TouchableOpacity
                style={[styles.fundHeaderDay]}
                onPress={() => {
                  Linking.openURL(item.investmentSheetURL);
                }}>
                <Text style={styles.TextDayReport}>{item.investmentName}</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View className={isMobile() ? 'h-[35px]' : ''}>
              <Text style={styles.textStyleRows}>{item.investmentName}</Text>
            </View>
          ),
          item.unitValue.value !== 0 ? (
            <View className={isMobile() ? 'h-[35px]' : ''}>
              <Text style={styles.textStyleRows}>
                {item.unitValue.value.toFixed(6)}
              </Text>
            </View>
          ) : item.unitValue.value === 0 &&
            item.unitValue.changeFromLastDate === 0 ? (
            <View className={isMobile() ? 'h-[35px]' : ''}>
              <Text style={styles.textStyleRows}>{'No Data Available'}</Text>
            </View>
          ) : (
            item.unitValue.value === 0 && (
              <View className={isMobile() ? 'h-[35px]' : ''}>
                <Text style={styles.textStyleRows}>
                  {item.unitValue.value.toFixed(0)}
                </Text>
              </View>
            )
          ),
          item.unitValue.changeFromLastDate !== 0 ? (
            <View className={isMobile() ? 'h-[35px]' : ''}>
              <Text style={styles.textStyleRows}>
                {item.unitValue.changeFromLastDate.toFixed(6)}
              </Text>
            </View>
          ) : item.unitValue.value === 0 &&
            item.unitValue.changeFromLastDate === 0 ? (
            <View className={isMobile() ? 'h-[35px]' : ''}>
              <Text style={styles.textStyleRows}>{'No Data Available'}</Text>
            </View>
          ) : (
            item.unitValue.changeFromLastDate === 0 && (
              <View className={isMobile() ? 'h-[35px]' : ''}>
                <Text style={styles.textStyleRows}>
                  {item.unitValue.changeFromLastDate.toFixed(0)}
                </Text>
              </View>
            )
          ),
        ],
      };
    });

    return (
      <div className="avoid-page-break">
        <View
          key={`fund${fundIndex}`}
          className="break-inside-avoid"
          style={styles.fundContainer}>
          <View className="flex flex-row justify-between w-full">
            <TouchableOpacity
              style={[styles.fundTitle, styles.fundHeader]}
              className="w-[65%] sm:w-[83%] lg:w-[90%]"
              onPress={() => toggleExpand(`fund${fundIndex}`)}>
              <FontAwesomeIcon
                icon={(isExpanded ? faChevronUp : faChevronDown) as IconProp}
                style={{
                  borderWidth: 0,
                  borderColor: 'transparent',
                  //@ts-ignore
                  outline: 'none',
                  flexShrink: 0, // Prevent the icon from shrinking
                  width: 14, // You can adjust the size here
                  height: 14,
                }}
                color={Color.oaBlue}
              />
              <Text style={styles.fundTitle}>{fund[0]?.assetClassName}</Text>
            </TouchableOpacity>
          </View>
          {isExpanded && (
            <View style={styles.tableContainer}>
              <OATable
                testID="reportResults"
                className="break-inside-avoid"
                headerColumns={[
                  'Investment Option',
                  'Value',
                  'Change from ' + changeDate,
                ]}
                hasNoData={tableData.length > 0}
                tableData={tableData.map((row) => row.cells)}
                horizontalScroll={isMobile() ? true : false}
                {...(isMobile() && {widthArr: [350, 170, 230]})}
                {...(!isMobile() && {flexArr: [3, 2, 3]})}
                style={styles.border}
                blueRows={true}
              />
            </View>
          )}
        </View>
      </div>
    );
  };

  const onPrint = () => {};

  const AllAssets: investmentClass[] = [];
  investmentData?.map((item) => {
    AllAssets.push({
      assetClassName: item.investment.assetClassName,
      assetClassValue: item.investment.assetClassValue,
      investmentName: item.investment.investmentName,
      investmentSheetURL: item.investment.investmentSheetURL,
      unitValue: item.unitValue,
      investments: [],
    });
  });

  const newArrayOfObjects = AllAssets.reduce((b: investmentClass[], a) => {
    const assetClassName = a.assetClassName;
    (b[assetClassName] = b[assetClassName] || []).push(a);

    return b;
  }, []);

  const sortedInvestmentData = Object.values(newArrayOfObjects);
  return (
    <View style={styles.container}>
      <>
        <Printable
          actionComponent={'link'}
          className={
            'absolute top-24 w-35 rounded-md bg-[#D1E5FA] py-2 px-2 no-underline z-10 hover:no-underline md:top-7 md:right-0'
          }
          size={14}
          color={'#2B4F8E'}
          hoverStyle={'none'}
          contentRef={contentRef}
          onPrint={onPrint}
          label={'Print report'}
          id="btnPrint"
          theme="tertiary"
        />
      </>
      <View ref={contentRef}>
        <View className="pb-12 md:flex-row justify-between md:pb-2 mb-2">
          <Text style={styles.title}>
            {isMobile()
              ? `Investment Unit Values - \nBy Date`
              : 'Investment Unit Values - By Date'}
          </Text>
        </View>
        <View style={styles.underline} />
        <View
          style={styles.tableView}
          className="w-full mt-6 sm:mt-10 lg:w-4/5">
          <TouchableOpacity
            style={styles.toggleButton}
            onPress={toggleVisibility}>
            <FontAwesomeIcon
              icon={
                (isVisible
                  ? faChevronSquareUp
                  : faChevronSquareDown) as IconProp
              }
              color={Color.oaBlue}
              style={{
                borderWidth: 0,
                borderColor: 'transparent',
                //@ts-ignore
                //outline: 'none',
              }}
            />
            <Text style={styles.toggleButtonText}>Asset Class</Text>
          </TouchableOpacity>

          <View style={styles.childContainer}>
            <ScrollView>
              {sortedInvestmentData.map((fund, index) =>
                renderFundItem(fund, index),
              )}
            </ScrollView>
          </View>
        </View>
      </View>
    </View>
  );
};

export default InvestmentUnitValuesDay;
