import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from 'react';
import {Pressable, View} from 'react-native';
import moment from 'moment';
import {Color} from '@oneamerica/dxp-ui-components';
import {DatePickerProps} from '.';
import {TextInput} from '../TextInput';
import {InputHelperText} from '../TextInput/components/InputHelperText';
import {Controls} from './controls';
import responsiveStyles from './styles';

const DatePicker = (props: DatePickerProps): ReactElement | null => {
  const {
    className,
    date,
    disabled = false,
    editable = true,
    ErrorList = [],
    format = 'MMMM DD, YYYY',
    helperText = '',
    inputSelectorName = 'date-picker',
    label,
    maxDate,
    minDate,
    onBlur,
    onChange = () => {},
    onChangeText = () => {},
    placeholder,
    rounded = true,
    showErrors = true,
    small = false,
    style,
    testID,
  } = props;

  const styles = responsiveStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | string | undefined>(
    date,
  );

  const onOpen = () => {
    if (inputRef.current) {
      inputRef?.current.showPicker();
    }
  };

  const onDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const newDate = moment(event.target.value).toDate();
      onChange(newDate);
      setSelectedDate(newDate);
    } else {
      onChange('');
      setSelectedDate('');
    }
  };

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  return (
    <View className={className}>
      <Pressable
        style={[
          small ? styles.small : styles.pressableContainer,
          style,
          disabled && styles.disabled,
        ]}
        disabled={disabled}>
        <TextInput
          Controls={
            <Controls
              small={small}
              onPress={onOpen}
              testID="pressable-control"
            />
          }
          id={inputSelectorName}
          editable={editable}
          ErrorList={ErrorList}
          focusable={false}
          label={label}
          onBlur={onBlur}
          pointerEvents="auto"
          testID={testID}
          value={selectedDate ? moment(selectedDate).format(format) : ''}
          rounded={rounded}
          showErrors={showErrors}
          small={small}
          style={styles.smallTextInput}
          onChangeText={(event) => onChangeText(event)}
          placeholder={placeholder}
        />
        <input
          disabled={disabled}
          aria-label={label}
          value={moment(selectedDate).format('YYYY-MM-DD')}
          max={maxDate && moment(maxDate).format('YYYY-MM-DD')}
          min={minDate && moment(minDate).format('YYYY-MM-DD')}
          onChange={onDateChange}
          ref={inputRef}
          // Inline styles allowed because this is web styling
          style={{
            visibility: 'hidden',
            position: 'absolute',
            bottom: 0,
            height: 0,
            right: 0,
            width: '100%',
            outlineStyle: 'none',
            border: 'none',
            backgroundColor: Color.input.disabledBackground,
          }}
          type="date"
        />
      </Pressable>
      {helperText.length > 0 && (
        <View style={styles.helperTextWrapper}>
          <InputHelperText message={helperText} type="info" />
        </View>
      )}
    </View>
  );
};

export {DatePicker};
