import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    modalTitle: {
      fontFamily: Font.regular.fontFamily,
      fontSize: 20,
      fontWeight: '400',
      zIndex: -1,
      padding: 2,
    },
    smModalSize: {
      margin: 'auto',
      maxHeight: '70%',
      maxWidth: '90%',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    linkContactUs: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
      //@ts-ignore
      display: 'inline-flex',
    },
    linkContactUsFocus: {
      textDecorationLine: 'underline',
      textDecorationColor: Theme.Color.linkFocus,
    },
    linkContactUsHover: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
    },
  })();
