import React, {useState, useEffect, useRef, FC} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Animated,
  TextStyle,
  ViewStyle,
  Pressable,
} from 'react-native';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Color, Utils} from '@oneamerica/dxp-ui-components';
import {styles} from './styles';
import {InputHelperText} from '../TextInput/components/InputHelperText';

interface MultiSelectProps {
  label: string;
  options: {label: string; value: string; investmentValue?: number}[];
  onSelect: (values: string[]) => void;
  maxSelections?: number;
  style?: ViewStyle;
  testID?: string;
  helperText?: string;
  selectedOptions?: {label: string; value: string}[];
}

const SELECT_ALL_OPTION = {label: 'Select All', value: 'select-all'};
const SELECT_MINE_OPTION = {label: 'Select Mine', value: 'select-mine'};

const AutocompleteMultiple: FC<MultiSelectProps> = ({
  label,
  options,
  onSelect,
  maxSelections = 10,
  style,
  testID,
  helperText = '',
  selectedOptions: initialSelectedOptions,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<
    {label: string; value: string}[]
  >([]);

  const [showOptions, setShowOptions] = useState(false);
  const focusAnim = useRef(new Animated.Value(0)).current;
  const [hoveredOption, setHoveredOption] = useState<string | null>(null);
  const dropdownRef = useRef<View | null>(null);

  useEffect(() => {
    if (initialSelectedOptions && initialSelectedOptions.length > 0) {
      setSelectedOptions(initialSelectedOptions);
    }
  }, [initialSelectedOptions]);

  const handleSelect = (value: string) => {
    const option = options.find((opt) => opt.value === value);
    if (!option) return;

    if (
      value === SELECT_ALL_OPTION.value ||
      value === SELECT_MINE_OPTION.value
    ) {
      setSelectedOptions([option]);
      onSelect([option.value]); //disbale individual selections when select all/ select mine is active
    } else {
      const alreadySelected = selectedOptions.some(
        (opt) => opt.value === value,
      );
      const newSelections = alreadySelected
        ? selectedOptions.filter((opt) => opt.value !== value)
        : [...selectedOptions, option].slice(0, maxSelections);

      setSelectedOptions(newSelections);
      onSelect(newSelections.map((opt) => opt.value));
    }
  };

  const handleClear = () => {
    setSelectedOptions([]);
    onSelect([]);
  };

  const handleRemove = (value: string) => {
    const newSelections = selectedOptions.filter((opt) => opt.value !== value);
    setSelectedOptions(newSelections);
    onSelect(newSelections.map((opt) => opt.value));
  };

  const filteredOptions = options
    .filter((option) => {
      if (
        selectedOptions.some((opt) =>
          [SELECT_ALL_OPTION.value, SELECT_MINE_OPTION.value].includes(
            opt.value,
          ),
        )
      ) {
        return selectedOptions.some((opt) => opt.value === option.value);
      }
      return !selectedOptions.some(
        (selected) => selected.value === option.value,
      );
    })
    .sort((a, b) => {
      if (a.value === SELECT_ALL_OPTION.value) return -1;
      if (b.value === SELECT_ALL_OPTION.value) return 1;
      if (a.value === SELECT_MINE_OPTION.value) return -1;
      if (b.value === SELECT_MINE_OPTION.value) return 1;

      return a.label.localeCompare(b.label);
    });

  useEffect(() => {
    Animated.timing(focusAnim, {
      toValue: selectedOptions.length > 0 ? 1 : 0,
      duration: 150,
      useNativeDriver: false,
    }).start();
  }, [selectedOptions]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOptions]);

  const labelStyle: Animated.AnimatedProps<TextStyle> = {
    position: 'relative',
    left: 2,
    top: focusAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [0, -18],
    }),
    fontSize: focusAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [15, 12],
    }),
    color: Color.text,
    backgroundColor: Color.background,

    zIndex: 1,
  };

  const containerStyle: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderColor: Color.gray3,
    backgroundColor: Color.background,
    borderWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 5,
    justifyContent: 'space-between',
    maxHeight: 700,
    minHeight: 50,
    position: 'relative',
    flexWrap: 'nowrap',
  };

  const rightIconsContainer: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8, // Push to the right
    gap: 10, // Add spacing between icons
  };

  const caretIconStyle: ViewStyle = {
    padding: 0,
    backgroundColor: 'transparent',
    borderWidth: 0,
  };

  return (
    <View
      ref={dropdownRef}
      testID={testID}
      style={[styles.mainContainer, style]}>
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => {
          setShowOptions(!showOptions);
        }}
        style={containerStyle}
        testID="autocomplete-multiple-touchable">
        <Animated.Text
          style={[
            labelStyle,
            {position: 'absolute', marginTop: 10, marginLeft: 8},
          ]}>
          {label}
        </Animated.Text>
        <ScrollView
          contentContainerStyle={{flexGrow: 1}}
          style={{flex: 1, marginTop: 12}}
          testID="autocomplete-multiple"
          keyboardShouldPersistTaps="handled"
          bounces={false}
          showsVerticalScrollIndicator={false}>
          <View style={styles.inputWrapper}>
            {selectedOptions.map((option, index) => (
              <View key={`${option.value}_${index}`} style={styles.tag}>
                <Text style={styles.tagText}>{option.label}</Text>
                <TouchableOpacity
                  onPress={() => handleRemove(option.value)}
                  testID={`remove-${option.value}`}
                  activeOpacity={1}
                  style={{
                    padding: 0,
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                  }}>
                  <FontAwesomeIcon
                    icon={Utils.icons.xMark as IconProp}
                    size={12}
                    color={Color.ice6}
                    style={[styles.iconStyle, styles.noBorder]}
                  />
                </TouchableOpacity>
              </View>
            ))}
          </View>
        </ScrollView>
        <View style={rightIconsContainer}>
          {selectedOptions.length > 0 && (
            <TouchableOpacity onPress={handleClear} style={{marginLeft: 10}}>
              <FontAwesomeIcon
                icon={Utils.icons.xMark as IconProp}
                size={12}
                color={Color.ice6}
              />
            </TouchableOpacity>
          )}
          {selectedOptions.length < maxSelections && (
            <View style={caretIconStyle}>
              <TouchableOpacity
                onPress={() => setShowOptions(!showOptions)}
                activeOpacity={1}
                accessible={false}
                style={{
                  padding: 0,
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                  borderColor: 'transparent',
                  elevation: 0,
                  shadowOpacity: 0,
                }}>
                <FontAwesomeIcon
                  icon={Utils.icons.caretDown as IconProp}
                  size={20}
                  style={[
                    styles.noBorder,
                    {
                      transform: [{rotate: showOptions ? '180deg' : '0deg'}],
                    },
                  ]}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
      </TouchableOpacity>
      {showOptions && (
        <ScrollView style={styles.optionsList} testID="options-list">
          {filteredOptions.map((item) => {
            return (
              <Pressable
                key={item.value}
                onHoverIn={() => setHoveredOption(item.value)}
                onHoverOut={() => setHoveredOption(null)}
                onPress={() => {
                  handleSelect(item.value);
                }}
                testID={`option-${item.value}`}>
                <View
                  style={[
                    styles.option,
                    {
                      backgroundColor:
                        hoveredOption === item.value
                          ? Color.backgroundGray
                          : 'transparent',
                    },
                  ]}>
                  <Text style={{marginLeft: 1}}>{item.label}</Text>
                </View>
              </Pressable>
            );
          })}
        </ScrollView>
      )}
      <View>
        {helperText.length > 0 && (
          <View style={styles.helperTextWrapper}>
            <InputHelperText message={helperText} type="info" />
          </View>
        )}
      </View>
    </View>
  );
};

export default AutocompleteMultiple;
