import {Theme} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    faFilePdfIcon: {
      color: ColorConstants.iron,
      fontSize: 15,
      outlineWidth: 0,
      marginRight: 8,
      marginLeft: 14,
    },
    latestStatement: {
      marginLeft: 8,
      color: Theme.Color.oaBlue,
    },
  })();
