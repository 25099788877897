import {useEffect, useState} from 'react';
import {useNavigation} from '@react-navigation/native';
import {DrawerNavigationProp} from '@react-navigation/drawer';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import RouteConstants from '../../constants/RouteConstants';

interface ProcessingTroubleModalProps {
  showModal: boolean;
  onClose?: () => void;
}

export const ProcessingTroubleModal = ({
  showModal,
  onClose,
}: ProcessingTroubleModalProps) => {
  const [showProcessingTroubleModal, setShowProcessingTroubleModal] =
    useState<boolean>(false);
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const styles = responsiveStyle();

  useEffect(() => {
    setShowProcessingTroubleModal(showModal);
  }, [showModal]);

  const navigateToContactUs = () => {
    setShowProcessingTroubleModal(false);
    navigation.navigate(RouteConstants.contactus);
  };

  const onCloseModal = () => {
    onClose?.();
  };

  const linkStyleContactUs = {
    style: styles.linkContactUs,
    linkStyle: styles.linkContactUs,
    linkHoverStyle: styles.linkContactUs,
    hoverStyle: styles.linkContactUsHover,
    focusStyle: styles.linkContactUsFocus,
  };

  return (
    <Components.DialogModal
      style={styles.smModalSize}
      backgroundColor="#F3F9FE"
      width={365}
      padding={20}
      visible={showProcessingTroubleModal}
      Header={
        <Components.Text style={styles.modalTitle}>
          Processing trouble
        </Components.Text>
      }
      onClose={onCloseModal}>
      <Components.Text className="grow-0 mt-[16px]">
        {'Apologies, we’re having some trouble processing your request.\n\n'}
        {
          'If you have any questions, please reference the Policy Services section on the '
        }
        <Components.Link
          onPress={navigateToContactUs}
          accessibilityLabel="Contact Us"
          accessibilityRole="button"
          {...linkStyleContactUs}>
          Contact Us
        </Components.Link>{' '}
        {'page.'}
      </Components.Text>
    </Components.DialogModal>
  );
};
